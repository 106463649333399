const saveListingControlsConfig = {
  title: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Title",
      maxLength: "50",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    orderIndex: 1,
    inputChangedHandler: "inputChangedHandler",
    label: "Title",
  },
  productNumber: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Product number",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
    orderIndex: 2,
    inputChangedHandler: "inputChangedHandler",
    label: "Product number",
  },
  type: {
    elementType: "select",
    elementConfig: {
      options: [],
    },
    value: "",
    validation: {},
    valid: true,
    orderIndex: 3,
    label: "Type of vehicle",
    inputChangedHandler: "inputVehicleTypeBrandModelChangeHandler",
  },
  brand: {
    elementType: "select",
    elementConfig: {
      options: [{ value: "", displayValue: "Select brand" }],
    },
    value: "",
    validation: {},
    valid: true,
    orderIndex: 4,
    label: "Brand",
    disabled: "disabled",
    inputChangedHandler: "inputVehicleTypeBrandModelChangeHandler",
  },
  modelName: {
    elementType: "select",
    elementConfig: {
      options: [{ value: "", displayValue: "Select model name" }],
    },
    value: "",
    validation: {},
    valid: true,
    orderIndex: 5,
    label: "Model name",
    disabled: "disabled",
    inputChangedHandler: "inputVehicleTypeBrandModelChangeHandler",
  },
  description: {
    elementType: "textarea",
    elementConfig: {
      type: "textarea",
      placeholder: "Description",
      rows: "4",
      maxLength: "1000",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    orderIndex: 6,
    inputChangedHandler: "inputChangedHandler",
    label: "Description",
  },
  price: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Price",
    },
    value: "",
    validation: {
      required: true,
      isNumericTwoDecimalPlaces: true,
    },
    valid: false,
    touched: false,
    orderIndex: 7,
    inputChangedHandler: "inputChangedHandler",
    label: "Price (£)",
  },
  condition: {
    elementType: "select",
    elementConfig: {
      name: "condition",
      options: [
        { value: "new", displayValue: "New" },
        { value: "nearlyNew", displayValue: "Nearly new" },
        { value: "used", displayValue: "Used" },
      ],
    },
    value: "new",
    validation: {},
    valid: true,
    orderIndex: 8,
    label: "Condition",
    inputChangedHandler: "inputChangedHandler",
  },
  photos: {
    elementType: "file",
    elementConfig: {
      type: "file",
      multiple: "multiple",
    },
    value: "",
    validation: {
      required: false,
    },
    content: "",
    valid: true,
    touched: false,
    orderIndex: 9,
    inputChangedHandler: "profilePhotoChangedHandler",
    label: "Upload photos",
  },
  selling: {
    elementType: "checkbox",
    elementConfig: {
      name: "selling",
      options: [{ value: "yes", checked: true }],
    },
    value: "yes",
    validation: {},
    valid: true,
    orderIndex: 10,
    label: "List this for sale?",
    inputChangedHandler: "inputCheckboxChangedHandler",
  },
  showInGarage: {
    elementType: "checkbox",
    elementConfig: {
      name: "showInGarage",
      options: [{ value: "yes", checked: true }],
    },
    value: "yes",
    validation: {},
    valid: true,
    orderIndex: 11,
    label: "Show this listing in my garage?",
    inputChangedHandler: "inputCheckboxChangedHandler",
  },
};

export default saveListingControlsConfig;
