// import React, { useEffect, useState } from "react";
import React from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import * as actions from "../../../store/actions/index";
// import useFormSelectVehicleTypeBrandModel from "../../../hooks/useFormSelectVehicleTypeBrandModel";
import { searchTypes } from "../../../containers/Search/searchConfig";

import Spinner from "../../../components/Spinner/Spinner";
// import Button from "../../../components/Button/Button";
// import { checkValidity } from "../../../shared/utility";
// import FormControlsCreator from "../../../components/FormControlsCreator/FormControlsCreator";
import Container from "../../../hoc/Layout/Container/Container";
import Listing from "../../../components/Search/Listing/Listing";
import ListingsContainer from "../../../components/Listings/ListingsContainer/ListingsContainer";
// import classes from "./SearchListings.module.scss";

const SearchListings = (props) => {
  const {
    // onSearch,
    // onShowAdvancedSearch: onShowHideAdvancedSearch,
    // onReinitialiseListingsState,
    listingsSearchResults,
    // vehicles,
    loading,
    // searchType,
    // error,
    isAuthenticated,
  } = props;
  // const controlsInitialState = {
  //   productNumber: {
  //     elementType: "input",
  //     elementConfig: {
  //       type: "text",
  //       placeholder: "Product number",
  //     },
  //     value: "",
  //     validation: {
  //       required: true,
  //     },
  //     valid: false,
  //     touched: false,
  //     orderIndex: 1,
  //     inputChangedHandler: "inputChangedHandler",
  //   },
  //   type: {
  //     elementType: "select",
  //     elementConfig: {
  //       options: [],
  //     },
  //     value: "",
  //     validation: {},
  //     valid: true,
  //     orderIndex: 4,
  //     label: "Type of vehicle",
  //     inputChangedHandler: "inputVehicleTypeBrandModelChangeHandler",
  //   },
  //   brand: {
  //     elementType: "select",
  //     elementConfig: {
  //       options: [{ value: "", displayValue: "Select brand" }],
  //     },
  //     value: "",
  //     validation: {},
  //     valid: true,
  //     orderIndex: 5,
  //     label: "Brand",
  //     disabled: "disabled",
  //     inputChangedHandler: "inputVehicleTypeBrandModelChangeHandler",
  //   },
  //   modelName: {
  //     elementType: "select",
  //     elementConfig: {
  //       options: [{ value: "", displayValue: "Select model" }],
  //     },
  //     value: "",
  //     validation: {},
  //     valid: true,
  //     orderIndex: 6,
  //     label: "Model name",
  //     disabled: "disabled",
  //     inputChangedHandler: "inputVehicleTypeBrandModelChangeHandler",
  //   },
  // };

  const listings = listingsSearchResults.map((listing) => {
    return (
      <Listing
        key={listing.id}
        listing={listing}
        isAuthenticated={isAuthenticated}
      />
    );
  });

  // const searchByControlInitialState = {
  //   modelName: {
  //     elementType: "radio",
  //     elementConfig: {
  //       name: "searchBy",
  //       options: [
  //         {
  //           value: "productNumber",
  //           displayValue: "Product number",
  //           checked: true,
  //         },
  //         { value: "vehicle", displayValue: "Vehicle", checked: false },
  //       ],
  //     },
  //     value: "",
  //     validation: {},
  //     valid: true,
  //     orderIndex: 6,
  //     label: "Search by",
  //     disabled: "disabled",
  //     inputChangedHandler: "inputRadioChangedHandler",
  //   },
  // };

  // const [searchByControl, setSearchByControl] = useState(
  //   searchByControlInitialState
  // );

  // const [searchBy, setSearchBy] = useState(
  //   searchByControl["modelName"].elementConfig.options.filter(
  //     (option) => option.checked
  //   )[0].value
  // );

  // const typeBrandModelControlsInitState = (({ type, brand, modelName }) => ({
  //   type,
  //   brand,
  //   modelName,
  // }))(controlsInitialState);

  // const [
  //   vehicleTypeBrandModelSelectControls,
  //   intialiseVehicleTypeBrandModelSelectControls,
  //   inputVehicleTypeBrandModelChangeHandler,
  // ] = useFormSelectVehicleTypeBrandModel(
  //   typeBrandModelControlsInitState,
  //   vehicles,
  //   {}
  // );

  // const otherControlsInitState = (({ productNumber }) => ({
  //   productNumber,
  // }))(controlsInitialState);
  // const [otherControls, setControls] = useState(otherControlsInitState);

  // const inputChangedHandler = (event, controlName) => {
  //   const updatedControls = {
  //     ...otherControls,
  //     [controlName]: {
  //       ...otherControls[controlName],
  //       value: event.target.value,
  //       valid: otherControls[controlName]
  //         ? checkValidity(
  //           event.target.value,
  //           otherControls[controlName].validation
  //         )
  //         : true,
  //       touched: true,
  //     },
  //   };
  //   setControls(updatedControls);
  // };

  // const inputRadioChangedHandler = (event, controlName) => {
  //   const updatedControls = {
  //     ...searchByControl,
  //     [controlName]: {
  //       ...searchByControl[controlName],
  //       elementConfig: {
  //         ...searchByControl[controlName].elementConfig,
  //         options: searchByControl[controlName].elementConfig.options.map(
  //           (option) => {
  //             return {
  //               ...option,
  //               checked: option.value === event.target.value,
  //             };
  //           }
  //         ),
  //       },
  //       touched: true,
  //     },
  //   };
  //   setSearchByControl(updatedControls);
  //   setSearchBy(
  //     updatedControls["modelName"].elementConfig.options.filter(
  //       (option) => option.checked
  //     )[0].value
  //   );
  // };

  // const submitHandler = (event) => {
  //   event.preventDefault();

  //   let searchCriteria = {};
  //   if (searchBy === "productNumber") {
  //     searchCriteria = {
  //       productNumber: otherControls.productNumber.value,
  //     };
  //   } else {
  //     const { type, brand, modelName } = {
  //       ...vehicleTypeBrandModelSelectControls,
  //     };
  //     searchCriteria = {
  //       type: type.value,
  //       brand: brand.value,
  //       modelName: modelName.value,
  //     };
  //   }

  //   onSearch(searchCriteria);
  // };

  // const showAdvancedSearch = (event) => {
  //   event.preventDefault();
  //   onShowHideAdvancedSearch(
  //     searchType === "freeTextSearch" ? "specificSearch" : "freeTextSearch"
  //   );
  // };

  // let searchByRadioOptions = (
  //   <FormControlsCreator
  //     controls={searchByControl}
  //     submitHandler={() => { }}
  //     eventHandlers={{
  //       inputRadioChangedHandler,
  //     }}
  //   />
  // );

  // let form = (
  //   <>
  //     <h3>By product number</h3>
  //     <FormControlsCreator
  //       disabled={searchBy !== "productNumber"}
  //       controls={otherControls}
  //       submitHandler={submitHandler}
  //       eventHandlers={{
  //         inputChangedHandler,
  //         inputVehicleTypeBrandModelChangeHandler,
  //       }}
  //     />

  //     <h3>By vehicle</h3>
  //     <FormControlsCreator
  //       disabled={searchBy !== "vehicle"}
  //       controls={vehicleTypeBrandModelSelectControls}
  //       submitHandler={submitHandler}
  //       eventHandlers={{
  //         inputChangedHandler,
  //         inputVehicleTypeBrandModelChangeHandler,
  //       }}
  //     />
  //   </>
  // );

  // useEffect(() => {
  //   intialiseVehicleTypeBrandModelSelectControls();
  // }, [
  //   intialiseVehicleTypeBrandModelSelectControls,
  //   onReinitialiseListingsState,
  // ]);

  return (
    <Container>
      {!loading ? (
        <h2>{listings.length} listings found.</h2>
      ) : (
        <h2>Searching for listings ......</h2>
      )}
      {/* <div className={classes.SearchLinks}>
        <Link to="#" onClick={showAdvancedSearch}>
          {searchType === "freeTextSearch"
            ? "Advanced search"
            : "Hide Advanced search"}
        </Link>
      </div>
      {searchType !== "freeTextSearch" && (
        <form className={classes.AdvancedSearch} onSubmit={submitHandler}>
          {searchByRadioOptions}
            {form}
          <Button>-- SEARCH --</Button>
        </form>
      )} */}

      {loading ? (
        <Spinner />
      ) : (
        <ListingsContainer>{listings}</ListingsContainer>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    vehicles: state.vehicles.vehicles,
    listingsSearchResults: state.search.listingsSearchResults,
    loading: state.search.loading,
    searchType: state.search.searchType,
    error: state.search.error,
    isAuthenticated: state.auth.token !== null && state.auth.emailVerified,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSearch: (searchCriteria) =>
      dispatch(
        actions.searchListings(searchCriteria, searchTypes.specificSearch)
      ),
    onShowAdvancedSearch: (searchType) =>
      dispatch(actions.changeSearchType(searchType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchListings);
