import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toSentaceCase } from "../../../shared/utility";
import { useHistory } from "react-router-dom";
import * as actions from "../../../store/actions/index";
import Spinner from "../../../components/Spinner/Spinner";
import { Swiper, SwiperSlide } from "swiper/react";
import MailtoLink from "../../../components/MailtoLink/MailtoLink";
import Container from "../../../hoc/Layout/Container/Container";

import classes from "./ListingDetails.module.scss";

import { Navigation, Pagination } from "swiper";

const ListingDetails = (props) => {
  const { onLoad, listing, match } = props;
  const history = useHistory();

  useEffect(() => {
    if (match.params.id && match.params.id !== listing.id) {
      onLoad(match.params.id);
    }
  }, [listing.id, match.params.id, onLoad]);

  if (props.loading) {
    return <Spinner />;
  }

  return (
    <Container>
      <div className={classes["Listing-details"]}>
        <div className={classes["Listing-details__listing"]}>
          {listing.photos && (
            <Swiper
              navigation={true}
              pagination={true}
              modules={[Pagination, Navigation]}
              className={classes["Listing-details__swiper"]}
              slidesPerView={1}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {listing.photos &&
                listing.photos.map((photo, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className={classes["Listing-details__swiper-slide"]}>
                        <img
                          className={classes["Listing-details__image"]}
                          alt=""
                          src={photo}
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          )}

          {/* <img
          className={classes["Listing-details__image"]}
          alt=""
          src={listing.photos[0]}
        /> */}

          <h2 className={classes["Listing-details__title"]}>{listing.title}</h2>

          {listing.brand && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Brand:</div>{" "}
              <div className="Listing-attribute__value">{listing.brand}</div>
            </div>
          )}

          {listing.productNumber && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Product No:</div>
              <div className="Listing-attribute__value">
                {listing.productNumber}
              </div>
            </div>
          )}

          {listing.modelName && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Model:</div>
              <div className="Listing-attribute__value">
                {listing.modelName}
              </div>
            </div>
          )}

          {listing.condition && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Conditon:</div>
              <div className="Listing-attribute__value">
                {toSentaceCase(listing.condition)}
              </div>
            </div>
          )}

          {listing.price && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Price:</div>
              <div className="Listing-attribute__value">£{listing.price}</div>
            </div>
          )}

          {listing.description && (
            <>
              <div className="Listing-attribute Listing-attribute__label">
                Description
              </div>
              <p className={classes["Listing-details__description"]}>
                {listing.description}
              </p>
            </>
          )}
        </div>

        <div className={classes["seller-info-container"]}>
          <div className={classes["seller-info-container__title"]}>
            {listing.nickname ? listing.nickname : listing.listerUserId}
          </div>
          <div className={classes["seller-contact-details"]}>
            Contact {listing.nickname ? listing.nickname : listing.listerUserId}
            <MailtoLink
              className={classes["seller-contact-details__email-button-link"]}
              email={listing.email}
              subject={`RCSHAK enquiry for listing ${listing.title} (${listing.productNumber})`}
              body={`Hi ${listing?.nickname}, \n\nI am interested in your listing. \n\nLISTING TITLE \n${listing.title} \n\nLISTING DESCRIPTION \n${listing.description} \n\nLISTING PRICE \n${listing.price} \n\nPlease contact me back. \n\nThanks \n\n  \n\nPowered by RCSHAK  https://www.rcshak.com \n\n`}
            >
              <div className={classes["seller-contact-details__email-button"]}>
                Email
              </div>
            </MailtoLink>
          </div>
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    listing: state.listings.listing,
    loading: state.listings.loading,
    error: state.listings.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (listingId) => dispatch(actions.fetchListingById(listingId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingDetails);
