import * as actionTypes from "../actions/actionsTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  listings: [],
  listing: {
    id: "",
    title: "",
    productNumber: "",
    type: "",
    brand: "",
    modelName: "",
    description: "",
    price: "",
    photos: [],
    showInGarage: false,
    selling: false,
    condition: "new",
  },
  error: null,
  loading: false,
};

const saveListingStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const reinitialseListingsState = (state) => {
  return updateObject({
    ...state,
    listing: {
      id: "",
      title: "",
      productNumber: "",
      type: "",
      brand: "",
      modelName: "",
      description: "",
      price: "",
      condition: "new",
      selling: "false",
      showInGarage: "false",
      photos: [],
    },
  });
};

const saveListingSuccess = (state, action) => {
  return updateObject(state, {
    listing: initialState.listing,
    error: null,
    loading: false,
  });
};

const saveListingFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const fetchListingStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const fetchListingSuccess = (state, action) => {
  return updateObject(state, {
    listing: action.listing,
    error: null,
    loading: false,
  });
};

const fetchLisingFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const fetchListingsStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const fetchListingsSuccess = (state, action) => {
  return updateObject(state, {
    listings: action.listings,
    error: null,
    loading: false,
  });
};

const fetchListingsFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_LISTING_START:
      return saveListingStart(state, action);
    case actionTypes.SAVE_LISTING_SUCCESS:
      return saveListingSuccess(state, action);
    case actionTypes.SAVE_LISTING_FAIL:
      return saveListingFail(state, action);
    case actionTypes.FETCH_LISTING_START:
      return fetchListingStart(state, action);
    case actionTypes.FETCH_LISTING_SUCCESS:
      return fetchListingSuccess(state, action);
    case actionTypes.FETCH_LISTING_FAIL:
      return fetchLisingFail(state, action);
    case actionTypes.FETCH_LISTINGS_START:
      return fetchListingsStart(state, action);
    case actionTypes.FETCH_LISTINGS_SUCCESS:
      return fetchListingsSuccess(state, action);
    case actionTypes.FETCH_LISTINGS_FAIL:
      return fetchListingsFail(state, action);
    case actionTypes.REINTIALISE_LISTINGS_STATE:
      return reinitialseListingsState(state);
    default:
      return state;
  }
};

export default reducer;
