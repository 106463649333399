import * as actionTypes from "./actionsTypes";
import axios from "axios";

export const loadAllVehiclesStart = () => {
  return {
    type: actionTypes.LOAD_ALL_VEHICLES_START
  };
};

export const loadAllVehicles = () => {
  // const token = localStorage.getItem("token");
  // axios.defaults.headers.common = { Authorization: `${token}` };


  return dispatch => {
    dispatch(loadAllVehiclesStart());

    return axios
      .get("/api/vehicles")
      .then(response => {
        return dispatch(loadAllVehiclesSuccess(response.data));
      })
      .catch(err => {
        console.log(err.message);
        dispatch(loadAllVehiclesFail(err.message));
      });
  };
};

export const loadAllVehiclesSuccess = (vehicles) => {
  return {
    type: actionTypes.LOAD_ALL_VEHICLES_SUCCESS,
    vehicles: vehicles,
  };
};

export const loadAllVehiclesFail = error => {
  return {
    type: actionTypes.LOAD_ALL_VEHICLES_FAIL,
    error: error
  };
};
