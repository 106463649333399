import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";

import Spinner from "../../components/Spinner/Spinner";
import Button from "../../components/Button/Button";
import Container from "../../hoc/Layout/Container/Container";
import Listing from "../../components/Listings/Listing/Listing";
import ListingsContainer from "../../components/Listings/ListingsContainer/ListingsContainer";
import { Link } from "react-router-dom";

import classes from "./Listings.module.scss";

const Listings = (props) => {
  const { onLoad, userId, isAuthenticated } = props;
  useEffect(() => {
    onLoad(userId);
  }, [onLoad, userId]);

  const listings = props.listings.map((listing) => {
    return (
      <Listing
        key={listing.id}
        listing={listing}
        isAuthenticated={isAuthenticated}
      />
    );
  });

  return (
    <Container>
      <h1>My listings</h1>

      <Button className={classes.Listings__AddAListing}>
        <Link to="/listings/listing/save">Add a listing</Link>
      </Button>

      {props.loading ? (
        <Spinner />
      ) : (
        <ListingsContainer>{listings}</ListingsContainer>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    listings: state.listings.listings,
    loading: state.listings.loading,
    error: state.listings.error,
    userId: state.auth.userId,
    isAuthenticated: state.auth.token !== null && state.auth.emailVerified,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (userId) => dispatch(actions.fetchListings(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Listings);
