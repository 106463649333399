import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import classes from "./Listing.module.scss";
import { toSentaceCase } from "../../../shared/utility";

const Listing = ({ listing, isAuthenticated }) => {
  const ListingNotAsLink = ({ listing }) => (
    <Fragment>
      <h3 className="Listing__title">{listing.title}</h3>
      <div className="Listing__main-content">
        <div>
          {listing.photos && (
            <img className="Listing__thumb" alt="" src={listing.photos[0]} />
          )}
        </div>

        <div>
          {listing.price && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Price:</div>
              <div className="Listing-attribute__value">£{listing.price}</div>
            </div>
          )}
          {listing.productNumber && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Product No:</div>
              <div className="Listing-attribute__value">
                {listing.productNumber}
              </div>
            </div>
          )}
          {listing.condition && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Conditon:</div>
              <div className="Listing-attribute__value">
                {toSentaceCase(listing.condition)}
              </div>
            </div>
          )}

          {listing.nickname && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">User:</div>
              <div className="Listing-attribute__value">
                <Link to={`/${listing.nickname.replace(/\s/g, "")}`}>
                  {listing.nickname}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );

  const ListingAsLink = ({ listing }) => (
    <Link
      className="Listing__link"
      to={`/listings/listing/details/${listing.id}`}
    >
      <ListingNotAsLink listing={listing} />
    </Link>
  );

  return (
    <div className="Listing">
      {/* {isAuthenticated ? (
        <ListingNotAsLink listing={listing} />
      ) : (
        <ListingAsLink listing={listing} />
      )} */}
      <ListingAsLink listing={listing} />
    </div>
  );
};

export default Listing;
