//for authentication
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const RESEND_VERIFICATION_EMAIL_START =
  "RESEND_VERIFICATION_EMAIL_START";
export const RESEND_VERIFICATION_EMAIL_SUCCESS =
  "RESEND_VERIFICATION_EMAIL_SUCCESS";
export const RESEND_VERIFICATION_EMAIL_FAIL = "RESEND_VERIFICATION_EMAIL_FAIL";

export const SEND_FORGOTTEN_PASSWORD_EMAIL_START =
  "SEND_FORGOTTEN_PASSWORD_EMAIL_START";
export const SEND_FORGOTTEN_PASSWORD_EMAIL_SUCCESS =
  "SEND_FORGOTTEN_PASSWORD_EMAIL_SUCCESS";
export const SEND_FORGOTTEN_PASSWORD_EMAIL_FAIL =
  "SEND_FORGOTTEN_PASSWORD_EMAIL_FAIL";

//my profile
export const FETCH_PROFILE_START = "FETCH_PROFILE_START";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAIL = "FETCH_PROFILE_FAIL";

export const UPDATE_PROFILE_START = "UPDATE_PROFILE_START";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const RESET_PROFILE_ERROR = "RESET_PROFILE_ERROR";

//my garage
export const LOAD_GARAGE_START = "LOAD_GARAGE_START";
export const LOAD_GARAGE_SUCCESS = "LOAD_GARAGE_SUCCESS";
export const LOAD_GARAGE_FAIL = "LOAD_GARAGE_FAIL";

export const SAVE_VEHICLE_START = "SAVE_VEHICLE_START";
export const SAVE_VEHICLE_SUCCESS = "SAVE_VEHICLE_SUCCESS";
export const SAVE_VEHICLE_FAIL = "SAVE_VEHICLE_FAIL";

//vehicles
export const LOAD_ALL_VEHICLES_START = "LOAD_ALL_VEHICLES_START";
export const LOAD_ALL_VEHICLES_SUCCESS = "LOAD_ALL_VEHICLES_SUCCESS";
export const LOAD_ALL_VEHICLES_FAIL = "LOAD_ALL_VEHICLES_FAIL";

//parts
export const SAVE_LISTING_START = "SAVE_LISTING_START";
export const SAVE_LISTING_SUCCESS = "SAVE_LISTING_SUCCESS";
export const SAVE_LISTING_FAIL = "SAVE_LISTING_FAIL";

export const FETCH_LISTING_START = "FETCH_LISTING_START";
export const FETCH_LISTING_SUCCESS = "FETCH_LISTING_SUCCESS";
export const FETCH_LISTING_FAIL = "FETCH_LISTING_FAIL";

export const FETCH_LISTINGS_START = "FETCH_LISTINGS_START";
export const FETCH_LISTINGS_SUCCESS = "FETCH_LISTINGS_SUCCESS";
export const FETCH_LISTINGS_FAIL = "FETCH_LISTINGS_FAIL";
export const REINTIALISE_LISTINGS_STATE = "REINTIALISE_LISTINGS_STATE";

//search
export const SEARCH_LISTINGS_START = "SEARCH_LISTINGS_START";
export const SEARCH_LISTINGS_SUCCESS = "SEARCH_LISTINGS_SUCCESS";
export const SEARCH_LISTINGS_FAIL = "SEARCH_LISTINGS_FAIL";

export const CHANGE_SEARCH_TYPE = "CHANGE_SEARCH_TYPE";
