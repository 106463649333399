import React from "react";
import classes from "./NavigationItems.module.scss";
import NavigationItem from "./NavigationItem/NavigationItem.js";

const navigationItems = (props) => (
  <ul className={classes.NavigationItems}>
    <NavigationItem link="/listings">My Listings</NavigationItem>
    <NavigationItem link="/garage">My Garage</NavigationItem>
    <NavigationItem link="/profile">My Profile</NavigationItem>
  </ul>
);

export default navigationItems;
