import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as actions from "../../../store/actions/index";
// import useFormSelectVehicleTypeBrandModel from "../../../hooks/useFormSelectVehicleTypeBrandModel";
import saveListingControlsConfig from "./formControlsConfig";
import Compressor from "compressorjs";
import Error from "../../../components/Error/Error";

import Spinner from "../../../components/Spinner/Spinner";
import FormControlsCreator from "../../../components/FormControlsCreator/FormControlsCreator";
import Container from "../../../hoc/Layout/Container/Container";
import Button from "../../../components/Button/Button";
import { checkValidity, isFormValid } from "../../../shared/utility";

import classes from "./SaveListing.module.scss";

const SaveListing = (props) => {
  const {
    onLoad,
    error,
    onSaveListing,
    onReinitialiseListingState,
    userId,
    email,
    listing,
    vehicles,
    match,
  } = props;
  const history = useHistory();

  // const [
  //   vehicleTypeBrandModelSelectControls,
  //   intialiseVehicleTypeBrandModelSelectControls,
  //   inputVehicleTypeBrandModelChangeHandler,
  // ] = useFormSelectVehicleTypeBrandModel(
  //   (({ type, brand, modelName }) => ({
  //     type,
  //     brand,
  //     modelName,
  //   }))(saveListingControlsConfig),
  //   vehicles,
  //   listing
  // );
  const [otherControls, setControls] = useState(
    (({
      title,
      productNumber,
      price,
      condition,
      description,
      photos,
      selling,
      showInGarage,
    }) => ({
      title,
      productNumber,
      description,
      price,
      condition,
      photos,
      selling,
      showInGarage,
    }))(saveListingControlsConfig)
  );

  const [hasValidationErrors, setHasValidationErrors] = useState(false);

  const inputChangedHandler = (event, controlName) => {
    const updatedControls = {
      ...otherControls,
      [controlName]: {
        ...otherControls[controlName],
        value: event.target.value,
        valid: otherControls[controlName]
          ? checkValidity(
              event.target.value,
              otherControls[controlName].validation
            )
          : true,
        touched: true,
      },
    };
    setControls(updatedControls);
  };

  const profilePhotoChangedHandler = (event, controlName) => {
    const value = event.target.value;
    const compressedImages = [];
    const imageCompressions = [];
    for (let file of Object.values(event.target.files)) {
      const compressedImage = new Promise((resolve, reject) => {
        new Compressor(file, {
          strict: true,
          success: resolve,
          error: reject,
          resize: "cover",
          checkOrientation: true,
          maxHeight: 1080,
          maxWidth: 1920,
          quality: 0.4,
        });
      })
        .then((result) => {
          console.log("Compress success");
          compressedImages.push(result);
        })
        .catch((err) => {
          console.log("Compress error");
          window.alert(err.message);
        })
        .finally(() => {
          console.log("Compress complete");
        });

      imageCompressions.push(compressedImage);
    }

    Promise.all(imageCompressions)
      .then(() => {
        const updatedControls = {
          ...controls,
          [controlName]: {
            ...controls[controlName],
            value,
            content: compressedImages,
            touched: true,
          },
        };
        setControls(updatedControls);
      })
      .catch((error) => {
        console.error(error);
        // return error;
      });
  };

  const inputCheckboxChangedHandler = (event, controlName) => {
    const updatedControls = {
      ...otherControls,
      [controlName]: {
        ...otherControls[controlName],
        elementConfig: {
          ...otherControls[controlName].elementConfig,
          options: otherControls[controlName].elementConfig.options.map(
            (option) => {
              return {
                ...option,
                checked: event.target.checked,
              };
            }
          ),
        },
        touched: true,
        value: event.target.checked ? "yes" : "no",
      },
    };
    setControls(updatedControls);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if (isFormValid(controls)) {
      const listingToSave = {};

      for (let formElementIdentifier in controls) {
        if (controls[formElementIdentifier].elementType === "file") {
          listingToSave[formElementIdentifier] = {
            value: controls[formElementIdentifier].value,
            content: (listingToSave[formElementIdentifier] =
              controls[formElementIdentifier].content),
          };
        } else {
          listingToSave[formElementIdentifier] =
            controls[formElementIdentifier].value;
        }
      }

      if (listing.id) listingToSave.id = listing.id;

      onSaveListing(listingToSave, userId, email).then(() => {
        history.push("/listings");
      });
    } else {
      setHasValidationErrors(true);
    }
  };

  const controls = {
    ...otherControls,
    // ...vehicleTypeBrandModelSelectControls,
  };

  let formControls = (
    <FormControlsCreator
      showValidationSummary={hasValidationErrors}
      controls={controls}
      eventHandlers={{
        inputChangedHandler,
        // inputVehicleTypeBrandModelChangeHandler,
        profilePhotoChangedHandler,
        inputCheckboxChangedHandler,
      }}
    />
  );

  // useEffect(() => {
  //   return onReinitialiseListingState;
  // }, [onReinitialiseListingState]);

  useEffect(() => {
    if (!match.params.id) {
      onReinitialiseListingState();
    }
  }, [match.params.id, onReinitialiseListingState]);

  useEffect(() => {
    if (match.params.id && match.params.id !== listing.id) {
      onLoad(userId);
    } else {
      // intialiseVehicleTypeBrandModelSelectControls();
      setControls((prevState) => {
        return {
          ...prevState,
          title: {
            ...prevState.title,
            value: listing.title,
            valid: checkValidity(listing.title, prevState.title.validation),
          },
          productNumber: {
            ...prevState.productNumber,
            value: listing.productNumber,
            valid: checkValidity(
              listing.productNumber,
              prevState.productNumber.validation
            ),
          },
          description: {
            ...prevState.description,
            value: listing.description,
            valid: checkValidity(
              listing.description,
              prevState.description.validation
            ),
          },
          price: {
            ...prevState.price,
            value: listing.price,
            valid: checkValidity(listing.price, prevState.price.validation),
          },
          selling: {
            ...prevState.selling,
            value: listing.selling,
            valid: checkValidity(listing.selling, prevState.selling.validation),
            elementConfig: {
              ...prevState.selling.elementConfig,
              options: [
                {
                  value: "yes",
                  checked: listing.selling === "yes",
                },
              ],
            },
          },
          showInGarage: {
            ...prevState.showInGarage,
            value: listing.showInGarage,
            elementConfig: {
              ...prevState.showInGarage.elementConfig,
              options: [
                {
                  value: "yes",
                  checked: listing.showInGarage === "yes",
                },
              ],
            },
          },
          condition: { ...prevState.condition, value: listing.condition },
        };
      });
    }
  }, [
    // intialiseVehicleTypeBrandModelSelectControls,
    onReinitialiseListingState,
    match.params.id,
    onLoad,
    listing.id,
    listing.title,
    listing.productNumber,
    listing.description,
    listing.price,
    listing.selling,
    listing.showInGarage,
    listing.condition,
    userId,
  ]);

  if (props.loading) {
    formControls = <Spinner />;
  }

  return (
    <Container>
      <h1>Save listing</h1>
      <h2>{listing.title}</h2>
      <form onSubmit={submitHandler}>
        {formControls}
        {error && (
          <p>
            <Error errors={[error]} />
          </p>
        )}
        {listing.photos && (
          <section className={classes["Listing__image-section"]}>
            <h3>Images</h3>
            {listing.photos.length > 0 ? (
              <div className={classes["Listing__images"]}>
                {listing.photos.map((photo) => (
                  <img
                    className={classes["Listing__image"]}
                    alt=""
                    key={photo}
                    height="20%"
                    width="20%"
                    src={photo}
                  ></img>
                ))}
              </div>
            ) : (
              <p>No images</p>
            )}
          </section>
        )}
        <Button classNames={`${hasValidationErrors ? "btn_disabled" : ""}`}>
          -- SAVE --
        </Button>
      </form>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    vehicles: state.vehicles.vehicles,
    listing: state.listings.listing,
    userId: state.auth.userId,
    email: state.auth.email,
    loading: state.listings.loading,
    error: state.listings.error,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSaveListing: (listing, userId, email) =>
      dispatch(actions.saveListing(listing, userId, email)),
    onLoad: (userId) =>
      dispatch(actions.fetchListing(ownProps.match.params.id, userId)),
    onReinitialiseListingState: () =>
      dispatch(actions.reinitialiseListingsState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveListing);
