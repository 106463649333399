import React from "react";
import { Link } from "react-router-dom";
import classes from "./Listing.module.scss";
import Button from "../../../components/Button/Button";
import { toSentaceCase } from "../../../shared/utility";

const Listing = ({ listing, isAuthenticated }) => {
  return (
    <div className="Listing">
      <h2 className="Listing__title">{listing.title}</h2>
      <div className="Listing__main-content">
        <div>
          {listing.photos && (
            <img className="Listing__thumb" alt="" src={listing.photos[0]} />
          )}
        </div>

        <div>
          {listing.price && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Price:</div>
              <div className="Listing-attribute__value">£{listing.price}</div>
            </div>
          )}
          {listing.productNumber && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Product No:</div>
              <div className="Listing-attribute__value">
                {listing.productNumber}
              </div>
            </div>
          )}
          {listing.condition && (
            <div className="Listing-attribute">
              <div className="Listing-attribute__label">Conditon:</div>
              <div className="Listing-attribute__value">
                {toSentaceCase(listing.condition)}
              </div>
            </div>
          )}

          <Button className="Listing__button">
            <Link to={`/listings/listing/save/${listing.id}`}>Edit</Link>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Listing;
