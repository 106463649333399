import * as actionTypes from "./actionsTypes";
import axios from "axios";

export const fetchListingStart = () => {
  return {
    type: actionTypes.FETCH_LISTING_START,
  };
};

export const fetchListingSuccess = (listing) => {
  return {
    type: actionTypes.FETCH_LISTING_SUCCESS,
    listing: listing,
  };
};

export const fetchListingFail = (error) => {
  return {
    type: actionTypes.FETCH_LISTING_FAIL,
    error: error,
  };
};

export const fetchListing = (id, userId) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common = { Authorization: `${token}` };

  return (dispatch) => {
    dispatch(fetchListingStart());

    return axios
      .get(`/api/user/${userId}/listings/listing/${id}`)
      .then((response) => {
        return dispatch(fetchListingSuccess(response.data));
      })
      .catch((err) => {
        console.log(err.message);
        dispatch(fetchListingFail(err.message));
      });
  };
};

export const fetchListingById = (listingId) => {
  return (dispatch) => {
    dispatch(fetchListingStart());

    return axios
      .get(`/api/listings/listing/${listingId}`)
      .then((response) => {
        return dispatch(fetchListingSuccess(response.data));
      })
      .catch((err) => {
        console.log(err.message);
        dispatch(fetchListingFail(err.message));
      });
  };
};

export const saveListingStart = () => {
  return {
    type: actionTypes.SAVE_LISTING_START,
  };
};

export const reinitialiseListingsState = () => {
  return {
    type: actionTypes.REINTIALISE_LISTINGS_STATE,
  };
};

export const saveListing = (listing, userId, email) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common = { Authorization: `${token}` };

  return (dispatch) => {
    //add the listing
    dispatch(saveListingStart());

    let form_data = new FormData();

    for (const field in listing) {
      if (field === "photos") {
        for (const [key, value] of Object.entries(listing[field].content)) {
          console.log(`${key}: ${value}`);
          form_data.append(`photos${key}`, value, value.name);
        }
      } else {
        form_data.append(field, listing[field]);
      }
    }

    if (listing.id) {
      return axios
        .put(
          `/api/user/${userId}/listings/listing/save/${listing.id}`,
          form_data
        )
        .then((response) => {
          dispatch(saveListingSuccess(response.data));
        })
        .catch((err) => {
          console.log(err.message);
          dispatch(saveListingFail(err.message));
        });
    } else {
      return axios
        .post(`/api/user/${userId}/listings/listing/save`, form_data)
        .then((response) => {
          dispatch(saveListingSuccess(response.data));
        })
        .catch((err) => {
          console.log(err.message);
          dispatch(saveListingFail(err.message));
        });
    }
  };
};

export const saveListingSuccess = (savedListing) => {
  return {
    type: actionTypes.SAVE_LISTING_SUCCESS,
    listing: savedListing,
  };
};

export const saveListingFail = (error) => {
  return {
    type: actionTypes.SAVE_LISTING_FAIL,
    error: error,
  };
};

export const fetchListingsStart = () => {
  return {
    type: actionTypes.FETCH_LISTINGS_START,
  };
};

export const fetchListingsSuccess = (listings) => {
  return {
    type: actionTypes.FETCH_LISTINGS_SUCCESS,
    listings: listings,
  };
};

export const fetchListingsFail = (error) => {
  return {
    type: actionTypes.FETCH_LISTINGS_FAIL,
    error: error,
  };
};

export const fetchListings = (userId) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common = { Authorization: `${token}` };

  return (dispatch) => {
    dispatch(fetchListingsStart());

    return axios
      .get(`/api/user/${userId}/listings`)
      .then((response) => {
        return dispatch(fetchListingsSuccess(response.data));
      })
      .catch((err) => {
        console.log(err.message);
        dispatch(fetchListingsFail(err.message));
      });
  };
};
