import React from "react";
import classes from "./Input.module.scss";

const Input = (props) => {
  let inputElement = null;
  const inputClasses = [classes.InputElement];

  if (props.invalid && props.shouldValidate && props.touched) {
    inputClasses.push(classes.Invalid);
  }
  switch (props.elementType) {
    case "input":
      inputElement = (
        <input
          id={props.name}
          className={inputClasses.join(" ")}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
          disabled={props.disabled}
        />
      );
      break;
    case "textarea":
      inputElement = (
        <textarea
          id={props.name}
          className={inputClasses.join(" ")}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
          disabled={props.disabled}
        />
      );
      break;
    case "select":
      inputElement = (
        <select
          id={props.name}
          className={classes.InputElement}
          value={props.value}
          onChange={props.changed}
          disabled={props.disabled}
        >
          {props.elementConfig.options.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.displayValue}
              </option>
            );
          })}
        </select>
      );
      break;
    case "radio":
      inputElement = (
        <>
          {props.elementConfig.options.map((option) => {
            return (
              <div key={option.value}>
                <input
                  type="radio"
                  id={option.value}
                  name={props.elementConfig.name}
                  value={option.value}
                  checked={option.checked}
                  onChange={props.changed}
                />
                <label htmlFor={option.value}>{option.displayValue}</label>
              </div>
            );
          })}
        </>
      );
      break;
    case "checkbox":
      inputElement = (
        <>
          {props.elementConfig.options.map((option) => {
            return (
              <div key={option.value}>
                <input
                  type="checkbox"
                  className={classes["InputElement__checkbox"]}
                  id={option.value}
                  name={props.elementConfig.name}
                  value={option.value}
                  checked={option.checked}
                  onChange={props.changed}
                />
                <label
                  className={classes["InputElement__checkbox__label"]}
                  htmlFor={option.value}
                >
                  {option.displayValue}
                </label>
              </div>
            );
          })}
        </>
      );
      break;
    case "file":
      inputElement = (
        <input
          id={props.name}
          className={inputClasses.join(" ")}
          {...props.elementConfig}
          onChange={props.changed}
          disabled={props.disabled}
        />
      );
      break;
    default:
      inputElement = (
        <input
          id={props.name}
          className={inputClasses.join(" ")}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
          disabled={props.disabled}
        />
      );
  }

  return (
    <div className={classes.Input}>
      {props.label && (
        <label htmlFor={props.name} className={classes.Label}>
          {props.label}
        </label>
      )}
      {inputElement}
    </div>
  );
};
export default Input;
