import { combineReducers } from "redux";
import authReducer from "./auth";
import profileReducer from "./profile";
import garageReducer from "./garage";
import listingsReducer from "./listings";
import vehiclesReducer from "./vehicles";
import searchReducer from "./search";

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  garage: garageReducer,
  listings: listingsReducer,
  vehicles: vehiclesReducer,
  search: searchReducer,
});

export default rootReducer;
