import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Compressor from "compressorjs";
import FormControlsCreator from "../../components/FormControlsCreator/FormControlsCreator";
import Container from "../../hoc/Layout/Container/Container";
import Spinner from "../../components/Spinner/Spinner";
import { checkValidity, isFormValid } from "../../shared/utility";
import Button from "../../components/Button/Button";
import Error from "../../components/Error/Error";
import * as actions from "../../store/actions/index";

import classes from "./Profile.module.scss";

const Profile = (props) => {
  const controlsIntialState = {
    firstName: {
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "First name",
      },
      value: "",
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      orderIndex: 1,
      label: "First name",
      inputChangedHandler: "inputChangedHandler",
    },
    surname: {
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Surname",
      },
      value: "",
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      orderIndex: 2,
      label: "Surname",
      inputChangedHandler: "inputChangedHandler",
    },
    nickname: {
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Nickname",
      },
      value: "",
      validation: {
        required: true,
      },
      touched: false,
      orderIndex: 3,
      label: "Nickname",
      inputChangedHandler: "inputChangedHandler",
    },
    favouriteVehicleType: {
      elementType: "select",
      elementConfig: {
        name: "favouriteVehicleType",
        options: [
          { value: "", displayValue: "-- Please select --" },
          { value: "airplanes", displayValue: "Airplanes" },
          { value: "boats", displayValue: "Boats" },
          { value: "cars", displayValue: "Cars" },
          { value: "drones", displayValue: "Drones" },
        ],
      },
      value: "cars",
      validation: {},
      valid: true,
      orderIndex: 4,
      label: "Favourite type of vehicle",
      inputChangedHandler: "inputChangedHandler",
    },
    profilePhoto: {
      elementType: "file",
      elementConfig: {
        type: "file",
      },
      value: "",
      content: "",
      touched: false,
      label: "Photo",
      inputChangedHandler: "profilePhotoChangedHandler",
    },
  };

  const { loading, error, profile, auth, onSave, onLoad } = props;

  const history = useHistory();
  const [controls, setControls] = useState(controlsIntialState);
  const [hasValidationErrors, setHasValidationErrors] = useState(false);

  const inputChangedHandler = (event, controlName) => {
    const updatedControls = {
      ...controls,
      [controlName]: {
        ...controls[controlName],
        value: event.target.value,
        valid: controls[controlName]
          ? checkValidity(event.target.value, controls[controlName].validation)
          : true,
        touched: true,
      },
    };
    setControls(updatedControls);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    if (isFormValid(controls)) {
      const updatedProfile = {};
      for (let formElementIdentifier in controls) {
        if (controls[formElementIdentifier].elementType === "file") {
          updatedProfile[formElementIdentifier] = {
            value: controls[formElementIdentifier].value,
            content: (updatedProfile[formElementIdentifier] =
              controls[formElementIdentifier].content),
          };
        } else {
          updatedProfile[formElementIdentifier] =
            controls[formElementIdentifier].value;
        }
      }

      try {
        await onSave(updatedProfile, profile.userId);
        history.push("/");
      } catch (e) {
        console.log(e);
      }
    } else {
      setHasValidationErrors(true);
    }
  };

  const profilePhotoChangedHandler = (event, controlName) => {
    const value = event.target.value;
    const compressedImages = [];
    const imageCompressions = [];
    for (let file of Object.values(event.target.files)) {
      const compressedImage = new Promise((resolve, reject) => {
        new Compressor(file, {
          strict: true,
          success: resolve,
          error: reject,
          quality: 0.2,
        });
      })
        .then((result) => {
          console.log("Compress success");
          compressedImages.push(result);
        })
        .catch((err) => {
          console.log("Compress error");
          window.alert(err.message);
        })
        .finally(() => {
          console.log("Compress complete");
        });

      imageCompressions.push(compressedImage);
    }

    Promise.all(imageCompressions)
      .then(() => {
        const updatedControls = {
          ...controls,
          [controlName]: {
            ...controls[controlName],
            value,
            content: compressedImages,
            touched: true,
          },
        };
        setControls(updatedControls);
      })
      .catch((error) => {
        console.error(error);
        // return error;
      });
  };

  let formControls = (
    <FormControlsCreator
      showValidationSummary={hasValidationErrors}
      controls={controls}
      eventHandlers={{
        inputChangedHandler,
        profilePhotoChangedHandler,
      }}
    />
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  useEffect(() => {
    setControls((prevState) =>
      Object.entries(prevState).reduce((obj, control) => {
        var updateControl = {
          ...control[1],
          value: profile[control[0]],
          valid: checkValidity(profile[control[0]], control[1].validation),
          touched: false,
        };
        obj[control[0]] = updateControl;
        return obj;
      }, {})
    );
  }, [profile]);

  if (loading) {
    formControls = <Spinner />;
  }

  return (
    <Container>
      <h1>My profile</h1>

      <form onSubmit={submitHandler}>
        {formControls}
        {error && (
          <p>
            <Error errors={[error]} />
          </p>
        )}
        {profile.profilePhoto && (
          <img alt="" height="20%" width="20%" src={profile.profilePhoto}></img>
        )}
        <br></br>
        <Button classNames={`${hasValidationErrors ? "btn_disabled" : ""}`}>
          -- Save --
        </Button>
      </form>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.profile.loading,
    error: state.profile.error,
    profile: state.profile,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSave: (updateProfile, userId) =>
      dispatch(actions.updateProfile(updateProfile, userId)),
    onLoad: () => dispatch(actions.fetchProfile()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
