import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import Listing from "../../components/Search/Listing/Listing";
import ListingsContainer from "../../components/Listings/ListingsContainer/ListingsContainer";
import Spinner from "../../components/Spinner/Spinner";
import Container from "../../hoc/Layout/Container/Container";
import classes from "./Garage.module.scss";

const Garage = (props) => {
  const { onLoad, userId, vehicles, error, loading, isAuthenticated } = props;
  useEffect(() => {
    onLoad(userId);
  }, [onLoad, userId]);

  const listings = vehicles.map((listing) => {
    return (
      <Listing
        key={listing.id}
        listing={listing}
        isAuthenticated={isAuthenticated}
      />
    );
  });

  return (
    <Container>
      <section className={classes.Aboutus}>
        <h2>
          Introducing The Garage: Your Personal RC Showcase - Coming Soon!
        </h2>
        <p>
          Get ready for The Garage, an innovative space where your passion for
          remote-controlled vehicles is about to take center stage. While we're
          gearing up for its full launch, dive into its current functionality
          that lets you highlight your selected RC vehicles.
        </p>
        <h3>Personal Showcase – Current Feature</h3>
        <p>
          Right now, The Garage allows you to handpick and display your favorite
          RC vehicles. It’s a glimpse into your hobby, showcasing the models
          that define your RC journey.
        </p>
        <h3>Seamless Sharing and Community Connection</h3>
        <p>
          Coming soon: a unique URL for each Garage. You’ll be able to share
          your complete collection with the world effortlessly. This feature
          will bridge connections with a global community of RC enthusiasts,
          fostering interactions and inspiration.
        </p>
        <h3>Your Hobby, Amplified</h3>
        <p>
          Stay tuned as we work to bring the full vision of The Garage to life.
          It’s not just about displaying or selling; it’s about celebrating
          every aspect of the RC vehicle hobby. At [RCSHAK.com], we’re committed
          to creating a space where your passion finds its perfect showcase.
        </p>
        <h3>The Future of RC Enthusiasm</h3>
        <p>
          Prepare to personalize, share, and connect like never before. The
          Garage is set to transform how you engage with your hobby and the RC
          community. Keep an eye out for updates and get ready to rev up your RC
          experience!
        </p>
      </section>

      {loading ? (
        <Spinner />
      ) : (
        <ListingsContainer>
          <h2>Garage listings</h2>
          {listings}
        </ListingsContainer>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.garage.loading,
    error: state.garage.error,
    vehicles: state.garage.vehicles,
    userId: state.auth.userId,
    isAuthenticated: state.auth.token !== null && state.auth.emailVerified,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (userId) => dispatch(actions.loadGarage(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Garage);
